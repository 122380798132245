<div class="modal-body">

    <div class="form-box">
        <button type="button" class="close" (click)="closeModal()">
            <span aria-hidden="true"><i class="icon-close"></i></span>
        </button>
        <h2 class="checkout-title">Forgot Password</h2>
        <form  id="user-form">
          <div class="row">
            <div class=" col-md-12">
                <div class="form-group kd-form-group">
                  <label for="register-email">Enter Registered Email *</label>
                  <input type="text" class="form-control" id="email" name="email"
                  [(ngModel)]='email' (change)="checkError('email')">
                    <span class="kd_error d-block m-0"
                    *ngIf="errors.emailError">Please enter a valid email</span>
                </div>
              </div>

              <div class="col-md-12" *ngIf="codesent">
                <div class="form-group kd-form-group">
                  <label for="register-email">Code</label>
                  <input type="text" class="form-control" id="code" name="code"
                  [(ngModel)]='code' (change)="checkError('code')">
                  <span class="kd_error d-block m-0"
                  *ngIf="errors.codeError">Code</span>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="codesent">
              <div class="col-md-12">
                <div class="form-group kd-form-group">
                  <label for="register-email">New Password</label>
                  <input type="password" class="form-control" id="password" (change)="checkError('password')" name="password"
                  [(ngModel)]='password'>
                    <span class="kd_error d-block m-0"
                    *ngIf="errors.passError">Please enter a valid password minimum 8 characters long.</span>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="codesent">
              <div class="col-md-12">
                <div class="form-group kd-form-group">
                  <label for="register-email">Confirm New Password</label>
                  <input type="password" class="form-control" id="confirmPassword" (change)="checkError('confirmPassword')" name="confirmPassword"
                  [(ngModel)]='confirmPassword'>
                    <span class="kd_error d-block m-0" *ngIf="errors.confirmPasswordError">
                      Password does not match
                    </span>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="!codesent">
              <div class="col-md-12">
                <div class="outer">
                  <button type="submit" class="btn button" (click)="submitForm()" style="padding: 10px 40px;">
                    <span class="color_w">Submit</span>&nbsp;&nbsp;<i *ngIf="submittingForm" class="fa fa-spinner fa-spin" style="font-size:20px"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="codesent">
              <div class="col-md-12">
                <div class="outer">
                  <button type="submit" class="btn button" (click)="resetPass()" style="padding: 10px 40px;">
                    <span class="color_w">Submit</span>&nbsp;&nbsp;<i *ngIf="resettingPass" class="fa fa-spinner fa-spin" style="font-size:20px"></i>
                  </button>
                </div>
              </div>
            </div>
        </form>
    </div>
</div>
