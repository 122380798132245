<div class="product product_1" *ngIf="product">
    <div class="product_img">
        <span class="product-label label-custom relative px-2" *ngIf="product && product.discount > 0">
            <span *ngIf="product.discount_type=='percent'">{{product.discount?.toString().replace('.00','')}}% OFF</span>
            <span *ngIf="product.discount_type=='flat'">Flat ${{product.discount?.toString().replace('.00','')}} OFF</span>
        </span>
        <img [src]="product.thumbnail_image?product.thumbnail_image:'{{assetPath}}assets/images/placeholder.jpg'" (click)="navigate2(product.slug)">
        <ng-container *ngIf="(!product.inventory || product.inventory <= 0) else secondBlock3">
            <div class="sold_out">
                <img src="{{assetPath}}assets/images/imgpsh_fullsize_anim.png">
            </div>
        </ng-container>
        <ng-template #secondBlock3>
        <div class="sold_out" *ngIf="!product.stock || product.stock == 0">
            <img src="{{assetPath}}assets/images/imgpsh_fullsize_anim.png">
        </div>
        </ng-template>
        <div class="wishlist_over_img" *ngIf="!product.wishlisted">
            <a href="javascript:;" (click)="addToWish(product)"><i class="fa fa-heart"></i></a>
        </div>
        <div class="wishlist_over_img" *ngIf="product.wishlisted">
            <a href="javascript:;" (click)="removeFromWish(product)"><i class="fa fa-heart red-icon"></i></a>
        </div>
        <div class="product_button">
            <div class="d-flex flex-row bd-highlight">
                <ng-container *ngIf="(!product.inventory || product.inventory <= 0) else secondBlock">
                    <div class="bd-highlight w-50"><a class="btn btn-black" href="javascript:;" disabled><i class="fa fa-shopping-cart"></i> Add to cart <i *ngIf="product.loading" class="fa fa-spinner fa-spin ml-1" style="font-size:16px"></i></a></div>
                </ng-container>
                <ng-template #secondBlock>
                    <div class="bd-highlight w-50" *ngIf="!product.stock || product.stock == 0"><a class="btn btn-black" href="javascript:;"><i class="fa fa-shopping-cart"></i> Add to cart <i *ngIf="product.loading" class="fa fa-spinner fa-spin ml-1" style="font-size:16px"></i></a></div>
                    <div class="bd-highlight w-50" *ngIf="product.stock && product.stock != 0"><a class="btn btn-black" href="javascript:;" (click)="addToCart(product)"><i class="fa fa-shopping-cart"></i> Add to cart <i *ngIf="product.loading" class="fa fa-spinner fa-spin ml-1" style="font-size:16px"></i></a></div>
                </ng-template>
                <div class="bd-highlight w-50"><a class="btn btn-orange" (click)="quickView(product,true)" href="javascript:;"><i class="fa fa-eye"></i> Quick View</a></div>
            </div>
        </div>
    </div>
    <div class="product_details" (click)="navigate2(product.slug)">
        <h3>{{product.name}}</h3>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div *ngIf="product.base_discounted_price>0 && product.base_discounted_price!=product.base_price" class="product_price">
                <p>${{ product.base_discounted_price.toFixed(2) }} <del>${{ product.base_price.toFixed(2) }}</del></p>
            </div>
            <div *ngIf="!product.base_discounted_price || product.base_discounted_price==product.base_price || product.base_discounted_price==0"
                class="product_price">
                <p>${{ product.base_price.toFixed(2) }}</p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="product_cart_button text-right">
                <ng-container *ngIf="(!product.inventory || product.inventory <= 0) else secondBlock2">
                    <a class="font1" href="javascript:;" disabled><img src="{{assetPath}}assets/images/shopping-cart-orange.svg"
                        style="width:20px;display: inherit;"> Add to cart <i *ngIf="product.loading" class="fa fa-spinner fa-spin ml-1" style="font-size:16px"></i></a>
                </ng-container>
                <ng-template #secondBlock2>
                    <a class="font1" href="javascript:;" disabled *ngIf="!product.stock || product.stock == 0"><img src="{{assetPath}}assets/images/shopping-cart-orange.svg"
                        style="width:20px;display: inherit;"> Add to cart <i *ngIf="product.loading" class="fa fa-spinner fa-spin ml-1" style="font-size:16px"></i></a>
                <a class="font1" href="javascript:;" (click)="addToCart(product)" *ngIf="product.stock && product.stock != 0"><img src="{{assetPath}}assets/images/shopping-cart-orange.svg"
                    style="width:20px;display: inherit;"> Add to cart <i *ngIf="product.loading" class="fa fa-spinner fa-spin ml-1" style="font-size:16px"></i></a>
                </ng-template>
            </div>
        </div>
    </div>
</div>
