<div class="modal-body">
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true"><i class="icon-close"></i></span>
  </button>

  <div class="form-box">
    <div class="form-tab nav-fill">
      <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills" [activeId]="activeTabId">
        <li  [ngbNavItem]="1">
          <a class="font1" ngbNavLink>Sign In</a>
          <ng-template ngbNavContent>
            <form
              action="#"
              [formGroup]="formGroup"
              (ngSubmit)="loginProcess()"
            >
              <div class="form-group kd-form-group">
                <label for="singin-email">Email *</label>
                <input
                  type="text"
                  class="form-control"
                  id="singin-email"
                  name="singin-email"
                  formControlName="email"
                  pattern="[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\.[a-z]{2,3}"
                />
                <span
                  class="kd_error"
                  *ngIf="email && email.invalid && email.touched"
                  >Please enter a valid email</span
                >
              </div>

              <div class="form-group kd-form-group">
                <label for="singin-password">Password *</label>
                <div class="input-group">
                  <input
                  type="{{ passwordVisibility['singin-password'] ? 'text' : 'password' }}"
                  class="form-control"
                  id="singin-password"
                  name="singin-password"
                  formControlName="password"
                  aria-label="Password" aria-describedby="eye-icon">
                  <button (click)="togglePasswordVisibility('singin-password')" class="btn btn-outline-secondary eye-icon" type="button" style="border:1px solid #ebebeb; min-width:auto;">
                    <i [class]="passwordVisibility['singin-password'] ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                  </button>
                </div>
                <span
                  class="kd_error has-error--text"
                  *ngIf="
                    (password.touched &&
                      password &&
                      control['password'].hasError('required') &&
                      control['password'].touched) ||
                    (control['password'].hasError('required') && signingup)
                  "
                  >Password is required</span
                >
                <span
                  class="kd_error has-error--text"
                  *ngIf="
                    (password.invalid &&
                      control['password'].hasError('pattern') &&
                      control['password'].touched) ||
                    (control['password'].hasError('pattern') && signingup)
                  "
                  >Password must of 8 characters with atleast one uppercase
                  alphabet and a numeric value</span
                >
              </div>
              <div class="form-footer">
                <div class="outer">
                  <button
                    type="submit"
                    class="btn button"
                    style="padding: 10px 0"
                  >
                    <span class="color_w">LOG IN</span> &nbsp;
                    <i
                      *ngIf="signingin"
                      class="fa fa-spinner fa-spin"
                      style="font-size: 20px"
                    ></i>
                    <i
                      *ngIf="!signingin"
                      class="icon-long-arrow-right color_w"
                    ></i>
                  </button>
                </div>

                <!-- <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="signin-remember">
                                    <label class="custom-control-label" for="signin-remember">Remember Me</label>
                                </div> -->

                <a
                  href="javascript:;"
                  class="forgot-link"
                  (click)="forgotPassword()"
                  >Forgot Your Password?</a
                >
              </div>
            </form>
            <div class="form-choice">
              <p class="text-center">or sign in with</p>
              <div class="row">
                <form
                  action="{{ baseUrl() }}/social-login/redirect/{{
                    redirectTo
                  }}"
                  id="social-login-form"
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="redirect_to"
                    value="{{ baseUrl() }}"
                  />
                </form>
                <div class="col-sm-6">
                  <a
                    href="javascript:;"
                    class="btn btn-login btn-g"
                    (click)="socialLogin('google')"
                  >
                    <i class="icon-google"></i>
                    Login With Google
                  </a>
                </div>
                <div class="col-sm-6">
                  <a
                    href="javascript:;"
                    class="btn btn-login btn-f"
                    (click)="socialLogin('facebook')"
                  >
                    <i class="icon-facebook-f"></i>
                    Login With Facebook
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li  [ngbNavItem]="2">
          <a class="font1" ngbNavLink>Register</a>
          <ng-template ngbNavContent>
            <form
              action="#"
              [formGroup]="formGroup1"
              (ngSubmit)="registerProcess()"
            >
              <div class="form-group kd-form-group">
                <label for="register-email">Full Name *</label>
                <input
                  type="text"
                  class="form-control"
                  id="register-first-name"
                  name="register-first-name"
                  formControlName="name"
                />
                <span
                  class="kd_error"
                  *ngIf="
                    signup_name && signup_name.invalid && signup_name.touched
                  "
                  >Please enter full name</span
                >
              </div>
              <!-- <div class="form-group">
                              <label for="register-email">Last Name *</label>
                              <input type="text" class="form-control" id="register-last-name" name="register-last-name" formControlName='l_name'>
                            </div> -->
              <div class="form-group kd-form-group">
                <label for="register-email">Email *</label>
                <input
                  type="email"
                  class="form-control"
                  id="register-email"
                  name="register-email"
                  formControlName="email"
                  pattern="[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\.[a-z]{2,3}"
                />
                <span
                  class="kd_error"
                  *ngIf="
                    signup_email && signup_email.invalid && signup_email.touched
                  "
                  >Please enter a valid email</span
                >
              </div>

              <!-- <div class="form-group kd-form-group">
                <label for="register-email">Your phone number *</label>
                <div class="enter-phone form-control d-flex pl-1">
                  <div class="country-image">
                    <img
                      src="{{
                        'http://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                          selectedCountry +
                          '.svg'
                      }}"
                      style="width: 24px"
                    />

                    <mat-form-field appearance="fill">
                      <input
                        type="text"
                        matInput
                        maxlength="7"
                        [formControl]="dialCodeControl"
                        (blur)="onBlurChangeCountry($event)"
                        [matAutocomplete]="auto"
                        (paste)="$event.preventDefault()"
                        #dialCodeControl1
                      />
                      <i
                        class="fa fa-caret-down input-icon"
                        aria-hidden="true"
                      ></i>
                      <mat-autocomplete
                        #auto="matAutocomplete"
                        [displayWith]="displayFn"
                      >
                        <mat-option
                          *ngFor="let item of filteredCountryCodes | async"
                          [value]="item"
                          (click)="onChangeCountry(item)"
                        >
                          <img
                            src="{{
                              'http://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                item.code +
                                '.svg'
                            }}"
                            style="width: 24px"
                          />&nbsp; <span>{{ item.dialCode }}</span> &nbsp;
                          <span>{{ item.code }}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>

                  <input
                    type="text"
                    formControlName="phone"
                    placeholder="Enter Your Phone Number"
                    (paste)="$event.preventDefault()"
                    class="form-control h-100 is-invalid"
                    (keypress)="commonService.numberOnly($event)"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid':
                        signup_phone.invalid &&
                        (signup_phone.dirty || signup_phone.touched)
                    }"
                  />
                </div>
                <span
                  class="kd_error"
                  *ngIf="
                    signup_phone && signup_phone.invalid && signup_phone.touched
                  "
                  >Please enter your phone number</span
                >
              </div> -->

              <div class="form-group kd-form-group">
                <label for="register-password">Password *</label>
                <div class="input-group">
                  <input
                  type="{{ passwordVisibility['register-password'] ? 'text' : 'password' }}"
                  class="form-control"
                  id="register-password"
                  name="register-password"
                  formControlName="password"
                  aria-label="Password" aria-describedby="eye-icon">
                  <button (click)="togglePasswordVisibility('register-password')" class="btn btn-outline-secondary eye-icon" type="button" style="border:1px solid #ebebeb; min-width:auto;">
                    <i [class]="passwordVisibility['register-password'] ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                  </button>
                </div>
                <small
                  class="kd_error has-error--text"
                  *ngIf="
                    (signup_password.touched &&
                      signup_password &&
                      controls['password'].hasError('required') &&
                      controls['password'].touched) ||
                    (controls['password'].hasError('required') && signingin)
                  "
                  >Password is required</small
                >

                <span
                  class="kd_error has-error--text"
                  *ngIf="
                    (signup_password.invalid &&
                      controls['password'].hasError('pattern') &&
                      controls['password'].touched) ||
                    (controls['password'].hasError('pattern') && signingin)
                  "
                  >Password must of 8 characters with atleast one uppercase
                  alphabet and a numeric value</span
                >
              </div>
              <div class="form-footer">
                <div class="outer">
                  <button
                    type="submit"
                    class="btn button"
                    style="padding: 10px 0"
                  >
                    <span class="color_w">SIGN UP</span> &nbsp;
                    <i
                      *ngIf="signingup"
                      class="fa fa-spinner fa-spin"
                      style="font-size: 20px"
                    ></i>
                    <i
                      *ngIf="!signingup"
                      class="icon-long-arrow-right color_w"
                    ></i>
                  </button>
                </div>
                <!--  <button type="submit" class="btn btn-outline-primary-2">
                                    <span>SIGN UP</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button> -->

                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="register-policy"
                    formControlName="privacy"
                  />
                  <label class="custom-control-label" for="register-policy"
                    >I agree to the privacy policy</label
                  >
                  <span
                    class="kd_error d-block"
                    *ngIf="privacy && privacy.invalid && privacy.touched"
                    >Please agree to our privacy policy.</span
                  >
                </div>
              </div>
            </form>
            <div class="form-choice">
              <p class="text-center">or sign in with</p>
              <div class="row">
                <div class="col-sm-6">
                  <a
                    href="javascript:;"
                    class="btn btn-login btn-g"
                    (click)="socialLogin('google')"
                  >
                    <i class="icon-google"></i>
                    Login With Google
                  </a>
                </div>
                <div class="col-sm-6">
                  <a
                    href="javascript:;"
                    class="btn btn-login btn-f"
                    (click)="socialLogin('facebook')"
                  >
                    <i class="icon-facebook-f"></i>
                    Login With Facebook
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
