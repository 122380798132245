<!-- <div class="top-header sticky-header" [class.fixed]="utilsService.isSticky">
  Huge clearance sale! Limited time only.
  <span style="cursor: pointer" role="button" routerLink="/category/offer-35yzh">Buy now</span>
</div> -->
<header class="header header-31 position-absolute">
  <div class="own_container">
    <div class="sticky-wrapper" [style.height]="
        utilsService.isSticky ? utilsService.stickyHeight + 'px' : ''
      ">
      <div class="header-middle sticky-header" [ngClass]="utilsService.isSticky ? 'fixed' : ''">
        <!-- <div [class]="containerClass"> -->

        <div class="header-top">
          <molla-header-top [mobile]="helpline" [contactEmail]="contactEmail"></molla-header-top>
        </div>

        <div [class]="containerClass">
          <div class="header-left">
            <molla-main-menu [items]="header_menu_left" [out_of_stock]="out_of_stock"></molla-main-menu>

            <molla-mobile-button></molla-mobile-button>
          </div>

          <div class="header-center">
            <a routerLink="/" class="logo desktop_header_logo">
              <img *ngIf="!logo" src="{{assetPath}}assets/images/logo-new.png" alt="Logo" width="150" class="" />
              <img *ngIf="logo" [src]="logo" alt="Logo" width="150" class="" />
            </a>

            <a routerLink="/" class="logo mobile_header_logo">
              <img *ngIf="!logo" src="{{assetPath}}assets/images/logo-new.png" alt="Logo" width="100" class="mobile_header_logo" />
              <img *ngIf="logo" [src]="logo" alt="Logo" width="100" class="mobile_header_logo" />
            </a>
          </div>

          <div class="header-right">
            <molla-main-menu-right [items]="header_menu_right" [out_of_stock]="out_of_stock"></molla-main-menu-right>
            <molla-header-search class="only_mobile_view"></molla-header-search>

            <!--

                        <molla-wishlist-menu></molla-wishlist-menu>

                        <molla-cart-menu></molla-cart-menu> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<header class="mini_header only_mobile_view">
  <div class="mini_header_box">
    <a (click)="toggleMenu($event)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
          d="M30 11.1a1.48 1.48 0 0 0 0-.21.83.83 0 0 0-.21-.5l-3.46-5.2A2.55 2.55 0 0 0 24.19 4H7.81a2.55 2.55 0 0 0-2.12 1.16l-3.46 5.2a.83.83 0 0 0-.21.5 1.48 1.48 0 0 0 0 .21v.06a.58.58 0 0 0 0 .18 7.63 7.63 0 0 0 2.94 5V27a3 3 0 0 0 3 3H24a3 3 0 0 0 3-3V16.26a7.63 7.63 0 0 0 2.94-5 .58.58 0 0 0 0-.18zm-3.4 3-.05.06a2.64 2.64 0 0 1-1.54 1 1.07 1.07 0 0 1-.25 0h-.08c-1.22 0-2.41-1.25-3-3.12h6a6.54 6.54 0 0 1-.93 1.85 1.56 1.56 0 0 1-.16.16zM7.35 6.27A.58.58 0 0 1 7.81 6h16.38a.58.58 0 0 1 .46.27L27.13 10H4.87zM19 12c-.63 1.87-1.81 3.12-3 3.12s-2.4-1.25-3-3.12zM4.32 12h6c-.62 1.87-1.81 3.12-3 3.12h-.07a1.07 1.07 0 0 1-.25 0 2.64 2.64 0 0 1-1.54-1v-.06a1.56 1.56 0 0 1-.16-.2A6.54 6.54 0 0 1 4.32 12zM12 28v-7.56a.44.44 0 0 1 .44-.44h7.12a.44.44 0 0 1 .44.44V28zm13-1a1 1 0 0 1-1 1h-2v-7.56A2.45 2.45 0 0 0 19.56 18h-7.12A2.45 2.45 0 0 0 10 20.44V28H8a1 1 0 0 1-1-1V17h.33a5.09 5.09 0 0 0 4.34-3A5.08 5.08 0 0 0 16 17a5.08 5.08 0 0 0 4.33-3 5.09 5.09 0 0 0 4.34 3H25z"
          style="fill: #fff" />
      </svg>
      <span> Shop </span>
    </a>
  </div>
  <div class="mini_header_box">
    <a routerLink="/cart">
      <div class="" style="width: 70px; margin: 0 auto; position: relative">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs><style>.cls-1{fill:#fff}</style></defs><g id="shopping_cart" data-name="shopping cart"><path class="cls-1" d="M29.74 8.32A1 1 0 0 0 29 8H13a1 1 0 0 0 0 2h14.91l-.81 9.48a1.87 1.87 0 0 1-2 1.52H12.88a1.87 1.87 0 0 1-2-1.52L10 8.92v-.16L9.33 6.2A3.89 3.89 0 0 0 7 3.52L3.37 2.07a1 1 0 0 0-.74 1.86l3.62 1.45a1.89 1.89 0 0 1 1.14 1.3L8 9.16l.9 10.49a3.87 3.87 0 0 0 4 3.35h.1v2.18a3 3 0 1 0 2 0V23h8v2.18a3 3 0 1 0 2 0V23h.12a3.87 3.87 0 0 0 4-3.35L30 9.08a1 1 0 0 0-.26-.76zM14 29a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm10 0a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"/><path class="cls-1" d="M15 18v-5a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0zM20 18v-5a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0zM25 18v-5a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0z"/></g></svg> -->
        <span class="cart-counter"></span>
        <svg class="icon-bag" aria-hidden="true" focusable="false" role="presentation"
          xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 22 24" fill="none">
          <path
            d="M6.91699 10.993V4.95104C6.91699 2.72645 8.70785 0.923065 10.917 0.923065C13.1261 0.923065 14.917 2.72645 14.917 4.95104V10.993"
            stroke="#fff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.131 23.0769C19.6697 23.0769 20.917 21.8209 20.917 20.2714C20.9174 20.1285 20.9067 19.9857 20.885 19.8445L19.221 8.95686C19.0463 7.81137 18.0679 6.96551 16.917 6.96503H4.917C3.76678 6.96536 2.78859 7.81016 2.613 8.95485L0.949001 19.8545C0.927336 19.9958 0.916636 20.1386 0.917001 20.2815C0.92251 21.827 2.16823 23.0769 3.703 23.0769H18.131Z"
            stroke="#fff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
        <span class="cart-icon-wrap"> Cart </span>
      </div>
    </a>
  </div>
  <div class="mini_header_box mini_header_box d-flex align-items-center" style="position: relative">
    <a routerLink="/" class="mini_header_box_center">
      <img src="{{assetPath}}assets/images/imgpsh_fullsize_anim (1).png" />
    </a>
  </div>
  <div class="mini_header_box">
    <a href="javascript:;" *ngIf="!loggedin" (click)="showLoginModal($event)">
      <svg version="1.1" id="shopping_x5F_carts_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128"
        style="enable-background: new 0 0 128 128" xml:space="preserve" width="23px">
        <style>
          .st0 {
            display: none;
          }

          .st1 {
            display: inline;
          }

          .st2 {
            fill: #fff;
          }
        </style>
        <g id="_x33__1_">
          <path class="st2"
            d="M51.5 97.4c-5.4 0-9.7 4.4-9.7 9.7 0 5.4 4.4 9.7 9.7 9.7s9.7-4.4 9.7-9.7c0-5.3-4.3-9.7-9.7-9.7zm0 13.9c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2c2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2zM19.7 13.4c-.3-1.3-1.4-2.2-2.7-2.2H2.8C1.3 11.2 0 12.4 0 14c0 1.5 1.2 2.8 2.8 2.8h11.9L41 92.4c.3 1.3 1.4 2.2 2.7 2.2h73.1V89H46L19.7 13.4zm84.6 84c-5.4 0-9.7 4.4-9.7 9.7 0 5.4 4.4 9.7 9.7 9.7 5.4 0 9.7-4.4 9.7-9.7.1-5.3-4.3-9.7-9.7-9.7zm0 13.9c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.8 4.2-4.2 4.2zM33.4 33.4l2.8 5.6h85s-.5 3.4-2.5 8.3H38.3l.7 2.8h78.5c-.9 2-2.1 4.2-3.5 6.5-.4.6-.9 1.2-1.4 1.8H41.1l.7 2.8h67.8c-7 5.6-18.5 8.3-25.6 8.3H44.5h.2-.2l2.8 5.6h33.4c16 0 29.1-4.9 36.2-13.9C126.4 49 128 33.4 128 33.4H33.4zm76.3 27.8 2.7-2.6c-.8.9-1.7 1.8-2.7 2.6z"
            id="icon_12_" />
        </g>
      </svg>
      <span> My Order </span>
    </a>
    <a routerLink="/orders" *ngIf="loggedin">
      <svg version="1.1" id="shopping_x5F_carts_1_" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 128 128"
        style="enable-background: new 0 0 128 128" xml:space="preserve" width="23px">
        <style>
          .st0 {
            display: none;
          }

          .st1 {
            display: inline;
          }

          .st2 {
            fill: #fff;
          }
        </style>
        <g id="_x33__1_">
          <path class="st2"
            d="M51.5 97.4c-5.4 0-9.7 4.4-9.7 9.7 0 5.4 4.4 9.7 9.7 9.7s9.7-4.4 9.7-9.7c0-5.3-4.3-9.7-9.7-9.7zm0 13.9c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2c2.3 0 4.2 1.9 4.2 4.2s-1.9 4.2-4.2 4.2zM19.7 13.4c-.3-1.3-1.4-2.2-2.7-2.2H2.8C1.3 11.2 0 12.4 0 14c0 1.5 1.2 2.8 2.8 2.8h11.9L41 92.4c.3 1.3 1.4 2.2 2.7 2.2h73.1V89H46L19.7 13.4zm84.6 84c-5.4 0-9.7 4.4-9.7 9.7 0 5.4 4.4 9.7 9.7 9.7 5.4 0 9.7-4.4 9.7-9.7.1-5.3-4.3-9.7-9.7-9.7zm0 13.9c-2.3 0-4.2-1.9-4.2-4.2s1.9-4.2 4.2-4.2 4.2 1.9 4.2 4.2-1.8 4.2-4.2 4.2zM33.4 33.4l2.8 5.6h85s-.5 3.4-2.5 8.3H38.3l.7 2.8h78.5c-.9 2-2.1 4.2-3.5 6.5-.4.6-.9 1.2-1.4 1.8H41.1l.7 2.8h67.8c-7 5.6-18.5 8.3-25.6 8.3H44.5h.2-.2l2.8 5.6h33.4c16 0 29.1-4.9 36.2-13.9C126.4 49 128 33.4 128 33.4H33.4zm76.3 27.8 2.7-2.6c-.8.9-1.7 1.8-2.7 2.6z"
            id="icon_12_" />
        </g>
      </svg>
      <span> My Order </span>
    </a>
  </div>
  <div class="mini_header_box">
    <a href="javascript:;" *ngIf="!loggedin" (click)="showLoginModal($event)">
      <svg class="icon-user" aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg"
        width="23" height="23" viewBox="0 0 26 26" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M13 24.5C19.3513 24.5 24.5 19.3513 24.5 13C24.5 6.64873 19.3513 1.5 13 1.5C6.64873 1.5 1.5 6.64873 1.5 13C1.5 19.3513 6.64873 24.5 13 24.5Z"
          stroke="#fff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>

        <path
          d="M4.95898 21.221C6.66657 20.2309 8.48298 19.4416 10.372 18.869C11.209 18.56 11.3 16.64 10.7 15.98C9.83398 15.027 9.09998 13.91 9.09998 11.214C8.99795 10.1275 9.36642 9.04944 10.1121 8.25272C10.8578 7.45599 11.9092 7.01703 13 7.047C14.0908 7.01703 15.1422 7.45599 15.8879 8.25272C16.6335 9.04944 17.002 10.1275 16.9 11.214C16.9 13.914 16.166 15.027 15.3 15.98C14.7 16.64 14.791 18.56 15.628 18.869C17.517 19.4416 19.3334 20.2309 21.041 21.221"
          stroke="#fff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
      <span> Account </span>
    </a>
    <a href="javascript:;" *ngIf="loggedin" routerLink="/my-account">
      <img *ngIf="loggedin" src="{{
          currentUser && currentUser?.avatar
            ? currentUser?.avatar
            : assetPath+'assets/images/user.png'
        }}" width="20" />
      <span> Account </span>
    </a>
  </div>
  <div class="clearfix"></div>
</header>
