import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Set the asset path globally
// (window as any).assetPath = environment.ASSET_PATH;

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
